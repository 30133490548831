import axios from "axios";
import { WEB_ROUTE, REQUESTS } from "./apiConfig";

const API_ROUTES = {
  createAPIKey: "organisation/apikey/create_api_key/",
  regenerateAPIKey: "organisation/apikey/regenerate_api_key/",
  deleteAPIKey: "organisation/apikey/delete_api_key/",
  fetchAPIKey: "organisation/apikey/fetch_api_key/"
};

export const createAPIKeyRequest = () => {
  return REQUESTS.get(API_ROUTES.createAPIKey, localStorage.getItem("token"));
};

export const regenerateAPIKeyRequest = () => {
  return REQUESTS.get(API_ROUTES.regenerateAPIKey, localStorage.getItem("token"));
};

export const deleteAPIKeyRequest = () => {
  return REQUESTS.get(API_ROUTES.deleteAPIKey, localStorage.getItem("token"));
};

export const fetchAPIKeyRequest = () => {
  return REQUESTS.get(API_ROUTES.fetchAPIKey, localStorage.getItem("token"));
};

