import "./index.scss";
import React, { useState } from "react";
import { Button, Spinner, Table } from "reactstrap";
import PageHeader from "../../../@core/components/PageHeader";
import { connect } from "react-redux";

const FraudDetectionFromDocument = () => {
  const [loading, setLoading] = useState(false);
  const [dataUploaded, setDataUploaded] = useState(false);

  const handleFileUpload = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setDataUploaded(true);
    }, 3000); // Simulate file processing
  };

  return (
    <div>
      <PageHeader pageName={"Fraud Detection"} backButtonEnabled={false} />
      <div className="container-fluid">
        <div
          className="page-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "0",
          }}
        >
          <div className="section-head" style={{ fontSize: "20px", color: "black" }}>
            Document-Based Fraud Detection
          </div>
        </div>
        <div style={{ padding: "10px" }} className="page-container no-scroll-bar">
          {!dataUploaded ? (
            <div style={{ display: "flex", gap: "20px" }}>
              {/* Left Container */}
              <div style={{ flex: 1, padding: "20px", boxShadow: "0 4px 6px rgba(0,0,0,0.1)", borderRadius: "8px" }}>
                <h4 style={{ fontSize: "18px", marginBottom: "15px" }}>Instructions</h4>
                <p>Please upload a CSV file with the following columns:</p>
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th>Column Name</th>
                      <th>Data Type</th>
                      <th>Requirement</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[
                      ["transaction_datetime", "datetime value", "Mandatory"],
                      ["merchant", "string", "Mandatory"],
                      ["purpose", "string", "Optional"],
                      ["amount", "float", "Mandatory"],
                      ["gender", "M/F", "Optional"],
                      ["location", "string", "Optional"],
                      ["city", "string", "Mandatory"],
                      ["state", "string", "Mandatory"],
                      ["pincode", "numeric", "Mandatory"],
                      ["job_profile", "string", "Optional"],
                      ["age", "numeric", "Mandatory"],
                      ["currency", "string", "Mandatory"],
                    ].map((row, index) => (
                      <tr key={index}>
                        <td>{row[0]}</td>
                        <td>{row[1]}</td>
                        <td>{row[2]}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              {/* Right Container */}
              <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button color="primary" onClick={handleFileUpload}>
                  {loading ? (
                    <>
                      <Spinner size="sm" style={{ marginRight: "10px" }} /> Uploading...
                    </>
                  ) : (
                    "Click to Upload CSV Document"
                  )}
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
                <h4 style={{ fontSize: "20px" }}>Fraud Analysis</h4>
                <div>
                  <Button color="primary" style={{ marginRight: "10px" }}>
                    Download as CSV
                  </Button>
                  <Button color="secondary" onClick={() => setDataUploaded(false)}>
                    Upload New Document
                  </Button>
                </div>
              </div>
              <div style={{ overflow: "auto" }}>
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th style={{ minWidth: "150px" }}>Fraud</th>
                      <th>Transaction Datetime</th>
                      <th>Merchant</th>
                      <th>Purpose</th>
                      <th>Amount</th>
                      <th>Gender</th>
                      <th>Location</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Pincode</th>
                      <th>Job Profile</th>
                      <th>Age</th>
                      <th>Currency</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(10)].map((_, index) => (
                      <tr key={index}>
                        <td style={{ backgroundColor: index % 2 === 0 ? "#ffefef" : "#ffffff" }}>
                          {index % 2 === 0 ? "Fraud" : "Not Fraud"}
                        </td>
                        <td>2024-12-05 14:30:00</td>
                        <td>Merchant {index + 1}</td>
                        <td>Purpose {index + 1}</td>
                        <td>{(Math.random() * 1000).toFixed(2)}</td>
                        <td>{["M", "F", ""][Math.floor(Math.random() * 3)]}</td>
                        <td>Location {index + 1}</td>
                        <td>City {index + 1}</td>
                        <td>State {index + 1}</td>
                        <td>{Math.floor(Math.random() * 100000)}</td>
                        <td>Job {index + 1}</td>
                        <td>{Math.floor(Math.random() * 60) + 18}</td>
                        <td>USD</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FraudDetectionFromDocument);