import "./index.scss";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Spinner, Table } from "reactstrap";
import { DollarSign, Eye, EyeOff, Copy, Trash2, RotateCw } from "react-feather";
import PageHeader from "../../../@core/components/PageHeader";
import { connect } from "react-redux";
import { showErrorToast, showSuccessToast } from "../../../utility/helper";
import {
  createAPIKeyRequest,
  deleteAPIKeyRequest,
  regenerateAPIKeyRequest,
  fetchAPIKeyRequest
} from "../../../services/api_key";

const FraudDetectionAPI = () => {
  const navigate = useNavigate();
  const [apiKey, setApiKey] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showKey, setShowKey] = useState(false);
  const [usageData, setUsageData] = useState([
    // Example usage data
    { date: "2024-12-01", usageCount: 150 },
    { date: "2024-12-02", usageCount: 180 },
  ]);

  useEffect(() => {
    setLoading(true);
    fetchAPIKeyRequest()
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          const apiKey = {
            key: res.data.key,
            createdOn: res.data.created_at,
            credits: 1000,
            usage: 0,
          };
          setApiKey(apiKey);
        }
      })
      .catch((error) => {
        setLoading(false);
        showErrorToast("Failed to fetch API Key");
      });
  }, []);

  const reGenerateApiKey = async () => {
    setLoading(true);
    regenerateAPIKeyRequest()
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          const newApiKey = {
            key: res.data.key,
            createdOn: res.data.created_at,
            credits: 1000,
            usage: 0,
          };
          setApiKey(newApiKey);
          showSuccessToast("API Key regenerated successfully!");
        } else {
          showErrorToast("Failed to regenerate API Key");
        }
      })
      .catch((error) => {
        setLoading(false);
        showErrorToast("Failed to regenerate API Key");
      });
  };

  const generateApiKey = async () => {
    setLoading(true);
    createAPIKeyRequest()
      .then((res) => {
        setLoading(false);
        if (res.status === 200 || res.status === 201) {
          const newApiKey = {
            key: res.data.key,
            createdOn: res.data.created_at,
            credits: 1000,
            usage: 0,
          };
          setApiKey(newApiKey);
          showSuccessToast("API Key generated successfully!");
        } else {
          showErrorToast("Failed to generate API Key");
        }
      })
      .catch((error) => {
        setLoading(false);
        showErrorToast("Failed to generate API Key");
      });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    showSuccessToast("Copied to clipboard!");
  };

  const deleteApiKey = () => {
    setLoading(true);
    deleteAPIKeyRequest()
    .then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setApiKey(null);
        setUsageData([]);
        showSuccessToast("API Key deleted successfully!");
      } else {
        showErrorToast("Failed to delete API Key");
      }
    })
    .catch((error) => {
      setLoading(false);
      showErrorToast("Failed to delete API Key");
    });
  };

  return (
    <div>
      <PageHeader pageName={"Fraud Detection API"} backButtonEnabled={false} />
      <div className="container-fluid">
        <div
          className="page-header"
          style={{ display: "flex", justifyContent: "space-between", marginTop: "0" }}
        >
          <div className="tab-container">
            <div
              className="section-head"
              style={{ fontSize: "20px", color: "black", paddingBottom: "10px" }}
            >
              API Key
            </div>
          </div>
          <div>
            <Button color="success" onClick={() => navigate("/products-and-plans")}>
              <DollarSign size={14} /> Upgrade
            </Button>
          </div>
        </div>
        <div style={{padding: '10px'}} className="page-container no-scroll-bar">
          {/* API Details Section */}
          <div style={{ padding: "20px", boxShadow: "0 4px 6px rgba(0,0,0,0.1)", backgroundColor: "#fff", borderRadius: "8px" }}>
            <h4 style={{ fontSize: "20px", marginBottom: "20px", color: "#333" }}>
                Why Choose Our Fraud Integration API?
            </h4>
            <ul style={{ fontSize: "16px", lineHeight: "1.6", listStyleType: "disc", paddingLeft: "20px" }}>
                <li>
                <strong>Advanced AI Models:</strong> Detects fraud with industry-leading accuracy, leveraging cutting-edge AI and machine learning.
                </li>
                <li>
                <strong>Customizable:</strong> Easily tailored to suit specific business needs across various industries.
                </li>
                <li style={{ marginBottom: "10px" }}>
                <strong>Data Security:</strong> Adheres strictly to global security standards; no PII data is stored or accessed.
                </li>
            </ul>
          </div>

          {/* API Key Section */}
          <div style={{marginTop: "50px"}}>
            {apiKey ? (
              <div
                style={{
                  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                  padding: "20px",
                  borderRadius: "8px",
                  backgroundColor: "#fff",
                  marginBottom: "20px",
                  fontSize: "16px",
                }}
              >
                <div style={{ display: "flex", justifyContent: "left" }}>
                  <div>
                    <strong>API Key:</strong>{" "}
                    <span>{showKey ? apiKey.key : "••••••••••••"}</span>
                  </div>
                  <div style={{marginTop:'-10px'}}>
                    <Button
                      color="link"
                      onClick={() => setShowKey(!showKey)}
                      style={{ width: '20px' }}
                    >
                      {showKey ? <EyeOff size={16} /> : <Eye size={16} />}
                    </Button>
                    <Button
                      color="link"
                      onClick={() => copyToClipboard(apiKey.key)}
                    >
                      <Copy size={16} />
                    </Button>
                  </div>
                </div>
                <div style={{marginBottom: '10px'}}>
                  <strong>Date Created:</strong> {apiKey.createdOn}
                </div>
                <div>
                  <strong>Credits Available:</strong> {apiKey.credits} (
                  {apiKey.usage}% used)
                </div>
                <div style={{ marginTop: "10px" }}>
                  <Button color="danger" onClick={deleteApiKey} style={{ marginRight: "10px" }}>
                    <Trash2 size={14} /> Delete API Key
                  </Button>
                  <Button color="warning" onClick={reGenerateApiKey}>
                    <RotateCw size={14} /> Regenerate API Key
                  </Button>
                </div>
                <p style={{ marginTop: "10px", fontSize: '16px' }}>
                  To check the usage of the API key, follow the instructions on{" "}
                  <a href="https://docs.insightai.in/api-reference/introduction" target="_blank" rel="noopener noreferrer">
                    https://docs.insightai.in
                  </a>
                </p>
              </div>
            ) : (
              <div style={{ marginBottom: "20px", textAlign: 'center' }}>
                <p>No API Key generated.</p>
                <Button color="primary" onClick={generateApiKey} disabled={loading}>
                  {loading ? (
                    <>
                      <Spinner size="sm" /> Please wait while we generate an API key for you
                    </>
                  ) : (
                    "Create One"
                  )}
                </Button>
              </div>
            )}
          </div>
          {/* API Usage Table */}
          {/* {apiKey && (
            <div>
              <h4>API Key Usage</h4>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Usage Count</th>
                  </tr>
                </thead>
                <tbody>
                  {usageData.map((entry, index) => (
                    <tr key={index}>
                      <td>{entry.date}</td>
                      <td>{entry.usageCount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(FraudDetectionAPI);
