/* eslint-disable */
import axios from 'axios';
import qs from 'qs';
import { store } from '../redux/store';
import { CreditAction } from '../redux/actions/credit';

const envType = {
  DEV: "development",
  STAGING: "staging",
  PROD: "production",
}

const develop = envType.STAGING;

const cred = {
  url: {
    development: "http://localhost:8080/",
    staging: "https://dev-app.insightai.in:8080/",
    // staging: "https://sharing-easy-python.ngrok-free.app/",
    production: "https://api-server.insightai.in:8080/",
  },
};

export const WEB_ROUTE = {
  urlPrefix: develop == envType.DEV ? cred.url.development : develop == envType.STAGING ? cred.url.staging : cred.url.production
}

export const REQUESTS = {

  get: async (url, token) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.common["ngrok-skip-browser-warning"] = "skip-browser-warning"
    return axios.get(`${WEB_ROUTE.urlPrefix}${url}`)
      .then(res => {
        return res
      })
      .catch((error) => {
        if (error.response.status == 400) {
          store.dispatch(CreditAction(error?.response?.data?.detail));
          return error
        } else {
          return error
        }
      })
  },
  post_without_token: async (url, body) => {
    return axios.post(`${WEB_ROUTE.urlPrefix}${url}`, qs.stringify({ ...body }))
      .then(res => {
        return res
      })
      .catch((error) => {
        if (error.response.status == 400) {
          store.dispatch(CreditAction(error?.response?.data?.detail));
          return error
        } else {
          return error
        }
      })
  },
  post: async (url, token, body) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return axios.post(`${WEB_ROUTE.urlPrefix}${url}`, qs.stringify({ ...body }))
      .then(res => {
        return res
      })
      .catch((error) => {

        if (error.response.status == 400) {
          store.dispatch(CreditAction(error?.response?.data?.detail));
          return error
        } else {
          return error
        }
      })
  },
  postJson: async (url, token, body) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    return axios.post(`${WEB_ROUTE.urlPrefix}${url}`, qs.stringify({ ...body }))
      .then(res => {

        return res
      })
      .catch((error) => {

        if (error.response.status == 400) {
          store.dispatch(CreditAction(error?.response?.data?.detail));
          return error
        } else {
          return error
        }
      })
  },
  postFile: async (url, token, body) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    return axios.post(`${WEB_ROUTE.urlPrefix}${url}`, body)
      .then(res => {

        return res
      })
      .catch((error) => {

        if (error.response.status == 400) {
          store.dispatch(CreditAction(error?.response?.data?.detail));
          return error
        } else {
          return error
        }
      })
  },

  put: async (url, token, body) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return axios.put(`${WEB_ROUTE.urlPrefix}${url}`, qs.stringify({ ...body }))
      .then(res => {
        return res
      })
      .catch((error) => {

        if (error.response.status == 400) {
          store.dispatch(CreditAction(error?.response?.data?.detail));
          return error
        } else {
          return error
        }
      })
  },
  patch: async (url, token, body) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return axios.patch(`${WEB_ROUTE.urlPrefix}${url}`, qs.stringify({ ...body }))
      .then(res => {
        return res
      })
      .catch((error) => {

        if (error.response.status == 400) {
          store.dispatch(CreditAction(error?.response?.data?.detail));
          return error
        } else {
          return error
        }
      })
  },
  delete: async (url, token) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return axios.delete(`${WEB_ROUTE.urlPrefix}${url}`)
      .then(res => {

        return res
      })
      .catch((error) => {

        if (error.response.status == 400) {
          store.dispatch(CreditAction(error?.response?.data?.detail));
          return error
        } else {
          return error
        }
      })
  },
  uploadFile: async (url, token, formData) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return axios.post(`${WEB_ROUTE.urlPrefix}${url}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(res => {

        return res;
      })
      .catch((error) => {

        if (error.response.status == 400) {
          store.dispatch(CreditAction(error?.response?.data?.detail));
          return error
        } else {
          return error
        }
      })
  },
}